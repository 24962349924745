import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from "react-router-dom"
import zhCN from 'antd/lib/locale/zh_CN'
import { ConfigProvider } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import {getTicketByToken} from '@a/js/tokenInfo.js'


import "@a/css/common.less"
import "@a/css/reset.css"

import App from './App'
import {memberURL,CSPURL,istoreUrl,metamallUrl,usercenter,agentClubUrl} from '@api/baseUrl'

import store from '../src/redux'


import {Provider} from 'react-redux'
moment.locale('en');

React.$goMember = function () {
  getTicketByToken().then((ticket)=>{
    window.location.href = memberURL + '?ticket='+ticket
  })
}

React.$goMatemall = function () {
  getTicketByToken().then((ticket)=>{
    window.location.href = metamallUrl + '?ticket='+ticket
  })
}

//登录跳转到其他项目
React.$toOtherSystem = function (returnUrl) {
  getTicketByToken().then((ticket)=>{
    // window.location.href = agentClubUrl + '?ticket='+ticket
    window.location.href = returnUrl + '?ticket='+ticket
  })
}

React.$istore = function () {
  getTicketByToken().then((ticket)=>{
    window.location.href = istoreUrl + '?ticket='+ticket
  })
}

React.$agentClub = function () {
  getTicketByToken().then((ticket)=>{
    // window.location.href = agentClubUrl + '?ticket='+ticket
    window.location.href = agentClubUrl
  })
}


React.$goCSP = function () {
  getTicketByToken().then((ticket)=>{
    window.open(CSPURL + '?ticket='+ticket)
  })
}

React.$goContacts = function () {
  getTicketByToken().then((ticket)=>{
    window.open(
      `${usercenter}/usercenter/setting/contacts?ticket=${ticket}`
    );
  })
}

React.$goAddress = function () {
  getTicketByToken().then((ticket)=>{
    window.open(
      `${usercenter}/usercenter/setting/address?ticket=${ticket}`
    );
  })
}

React.$goInvoice = function () {
  getTicketByToken().then((ticket)=>{
    window.open(
      `${usercenter}/usercenter/setting/invoice?ticket=${ticket}`
    );
  })
}

//登录跳转到其他项目
React.$toOtherSystem = function (returnUrl) {
  getTicketByToken().then((ticket)=>{
    // window.location.href = agentClubUrl + '?ticket='+ticket
    if(returnUrl.indexOf('?') !== -1){
      window.location.href = returnUrl + '&ticket='+ticket
    }else{
      window.location.href = returnUrl + '?ticket='+ticket
    }
  })
}

ReactDOM.render(
  // <React.StrictMode>
  <ConfigProvider locale={zhCN}>
    <BrowserRouter>
      <Provider store={store}>
        <App/>
      </Provider>
    </BrowserRouter>
  </ConfigProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);