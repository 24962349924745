import {LOGININFO} from '../actionsType'

const initState = localStorage.getItem('user') !== null || localStorage.getItem('user') ? localStorage.getItem('user') : JSON.stringify({name:'',times:''});

export default function allInfo(state=initState,action){
	const {type,data} = action
	switch (type) {
		case LOGININFO: 
			let dataCopy = data;
			localStorage.setItem('user', data);
			return dataCopy
		default:
			return state
	}
}
