import React, { useEffect } from 'react';
import qs from 'query-string';
import { Router, withRouter, Switch, Redirect } from 'react-router-dom';
import routes from '@router/routeConfig';
import renderRouter from "@router";
import {getTokenByTicket,getUserByToken} from '@a/js/tokenInfo.js'
import {postLogOut} from "./api/index"

import history from '@a/js/history.js'

const App = (props) => {
  useEffect(()=>{
    const { search } = props.location;
    //如果有returnUrl就存在session里面,点击登录后跳进returnUrl的系统
    if(qs.parse(window.location.search) && qs.parse(window.location.search).returnUrl){
      sessionStorage.setItem('returnUrl',qs.parse(window.location.search).returnUrl)
    }
    let { ticket} = qs.parse(search.slice(1))?qs.parse(search.slice(1)):"";
    if((ticket && !localStorage.getItem('ticket')) || (ticket && localStorage.getItem('ticket') !== ticket)){    
      // localStorage.removeItem('user');
            localStorage.setItem('ticket',ticket);
            // 获取token 
            getTokenByTicket().then(()=>{
              getUserByToken()
            })
    }else{    
      if(!ticket && !localStorage.getItem('token') && (window.location.pathname!=="/register" && window.location.pathname!=="/forget"&& window.location.pathname!=="/login"&& window.location.pathname!=="/trustCenter")){
        //如果用户直接访问地址栏 进入
        //直接退出登录 到登录页
        postLogOut().then(res => {
          console.log(res);
        });
      } 
      // else if (window.location.pathname !== '/register' || window.location.pathname !== '/forget') {
      //   setIsFinish(true);
      // } else {
      //   setIsFinish(true);
      // }
    }
  },[])  // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div style={{ height: '100%', background: '#fff' }}>
      {
        <Router history={history}>
          <Switch>
            {renderRouter(routes)}
            <Redirect from='/' to='/usercenter/homepage'></Redirect>
          </Switch>
        </Router>
        
      }
    </div>
  );
};

export default withRouter(App);








