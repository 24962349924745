import axios from 'axios'
import { loginURL } from '@api/baseUrl'

let getTokenByTicket = () => {
    return new Promise(resolve => {
        axios.post(loginURL + '/oauth/getTokenByTicket', {
            ticket: localStorage.getItem('ticket')
        }, {
            headers: {
                'Content-type': 'application/json;charset=utf-8'
            },
        }).then(r => {
            if (r.data.code === '0') {
                localStorage.setItem('token',r.data.data.token)
                resolve(r.data.data.token)
            }else{
                localStorage.removeItem('user');
            }
        }).catch(e => {
            localStorage.removeItem('user');
        })
    })
}

let getTicketByToken = () => {
    return new Promise(resolve => {
        axios.post(loginURL + '/oauth/getTicketByToken', {
            token: localStorage.getItem('token')
        }, {
            headers: {
                'Content-type': 'application/json;charset=utf-8'
            },
        }).then(r => {
            if (r.data.code === '0') {
                resolve(r.data.data.ticket)
            }
        })
    })
}

let getUserByToken = (props) => {
    return new Promise(resolve => {
        axios.post(loginURL + '/oauth/getUserByToken', {
            token: localStorage.getItem('token')
        }, {
            headers: {
                'Content-type': 'application/json;charset=utf-8'
            }
        }).then(r => {
            if (r.data.code === '0') {
                // dispatch({type:'LOGININFO',data:JSON.stringify(r.data.data)})
                // sessionStorage.setItem('storeInfo',JSON.stringify(r.data.data))
                localStorage.setItem('user',JSON.stringify(r.data.data))
                resolve('ok')
            }else{
                if(window.location.pathname!=="/userfront/Services"){
                    localStorage.removeItem('user');
                }
            }
        }).catch(e => {
            localStorage.removeItem('user');
        })
    })
       
}


export { getTokenByTicket,getTicketByToken,getUserByToken }