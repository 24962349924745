import request from "@api/axios.js";
// import {baseURL,loginURL} from "./baseUrl"
let application = 'application/x-www-form-urlencoded;charset=UTF-8'
//公用短信 - 不存在手机号会报错
export const  postGetPhoneCode = params=>request(`/common/getPhoneCode`,params);

//公用短信 - 存在手机号会报错
export const  postGetSmsCode = params=>request(`/common/getSmsCode`,params);
export const  postGenCaptcha = params=>request(`/common/genCaptcha`,params,"","get");

//忘记密码
export const  postSendEmailCode = params=>request(`/common/sendEmailCode`,params);
//改绑邮箱
export const  postChangeEmailCode = params=>request(`/commonly/sendEmailCode`,params);


//首页
export const postQueryHomePage = params=>request(`/userCenterUser/queryHomePage`,params);

//我的账号
export const postQueryAccount = params=>request(`/userCenterAccount/queryAccount`,params);

// 改绑手机
export const postChangePhone = params=>request(`/userCenterAccount/updatePhone`,params);

// 改绑邮箱
export const postUpdateEmail = params=>request(`/userCenterAccount/updateEmail`,params);

//修改密码
export const postChangePwd = params=>request(`/userCenterAccount/updatePwd`,params);

//企业认证
export const postQueryAuthInfo = params=>request(`/authentication/queryAuthInfo`,params);
export const postGetAgreementUrl = params=>request(`/authentication/getLegalAgreementUrl`,params);

//查看授权页面
export const postQueryAuthorization = params=>request(`/authorization/queryAuthorizationByAccountId`,params);
export const postMail = params=>request(`/authorization/updateAuthorizationMail`,params);

// 发票信息
export const postSelectAuthenticationInfo = params=>request(`/invoice/selectAuthenticationInfo`,params);
export const postSaveInvoiceInfo = params=>request(`/invoice/saveInvoiceInfo`,params);


// 认证
export const postAuthenticationApply = params=>request(`/authentication/authenticationSubmit`,params);
export const postLicensesOcr = params=>request(`/authentication/licensesOcr`,params);

export const postQueryPassportOcr = params=>request(`/authentication/queryPassportOcr`,params);

export const postOcrLegalIdCardBack = params=>request(`/authentication/ocrLegalIdCardBack`,params);
export const postOcrLegalIdCardFront = params=>request(`/authentication/ocrLegalIdCardFront`,params);
export const postModifyAuthInfo = params=>request(`/authentication/modifyAuthInfo`,params);
//公司的模糊搜索
export const postQccQueryByCompanyName = params=>request(`/authentication/qccQueryByCompanyName`,params);


//登录
export const getUsercenterClientFun = params=>request(`/client/getUsercenterClient`,params,application);
export const postLogin = (params, clientKey)=>request(`/oauth/login`,params,application,'post','needKey',clientKey);
export const getUserByToken = params=>request(`/oauth/getUserByToken`,params);
export const saveAgreement = params=>request(`/oauth/saveAgreement`,params);
export const updateLoginName = params=>request(`/loginM/updateLoginName`,params);
export const checkCompanyName = params=>request(`/loginM/checkCompanyName`,params);
export const updatePhoneNum = params=>request(`/loginM/updatePhoneNum`,params);
export const updateEmail = params=>request(`/loginM/updateEmail`,params);
export const getSmsCode = params=>request(`/oauth/getSmsCode`,params);






//登出
export const postLogOut = params=>request(`/logout`,params);
//用户名为手机号格式 -- 修改用户名
export const setLoginNameSubimt=parmas=>request(`/loginM/updateLoginName`,parmas);
//用户手机号 是否对应一个企业以上 -- 查询
export const inquireUserPhone=parmas=>request(`/loginM/checkCompanyName`,parmas);


//注册
export const postRegister = params=>request(`/register/register`,params);
export const postAgreementUrl = params=>request(`/register/getAgreementUrl`,params);
export const checkLoginNameUrl = params=>request(`/userCenterAccount/checkLoginName`,params);

//忘记密码
export const postForget = params=>request(`/userCenterAccount/retrievepwd`,params);
//手机短信验证
export const phoneSendVerify=parmas=>request(`/common/checkSmsCode`,parmas);
//邮箱短信验证
export const emaliSendVerify=parmas=>request(`/common/checkEmailCode`,parmas);

//收货地址接口
    //新增
    export const postSave = params=>request(`/userCenterConsignee/saveConsignee`,params);
    //删除
    export const postDelete = params=>request(`/userCenterConsignee/deleteConsignee`,params);
    //获取修改
    export const getUpdate = params=>request(`/userCenterConsignee/queryConsigneeInfoById`,params);
    //修改
    export const postUpdate = params=>request(`/userCenterConsignee/updateConsignee`,params);
    //分页
    export const postQuery = params=>request(`/userCenterConsignee/queryConsigneeInfo`,params);
    //默认
    export const postDefault = params=>request(`/userCenterConsignee/updateIsDefault`,params);
 

//联系人接口
    //新增
    export const linkPostSave = params=>request(`/userCenterLink/saveLinkInfo`,params);
    //删除
    export const linkPostDelete = params=>request(`/userCenterLink/deleteLinkInfo`,params);
    //获取修改
    export const linkGetUpdate = params=>request(`/userCenterLink/queryLinkInfoById`,params);
    //修改
    export const linkPostUpdate = params=>request(`/userCenterLink/updateLinkInfo`,params);
    //分页
    export const linkPostQuery = params=>request(`/userCenterLink/queryLinkInfo`,params);
    //默认
    export const linkPostDefault = params=>request(`/userCenterLink/updateIsDefaultLink`,params);

//合同协议
    //分页
    export const postAgree = params=>request(`/userCenterAgreement/queryAgreementRecord`,params);
    //合同下拉
    export const postAgreeSelect = params=>request(`/userCenterAgreement/groupQueryAgreement`,params);
    //下载协议
    export const postPdfBase64 = params=>request(`/userCenterAgreement/downloadFile`,params);

    //线下授权
    export const postSaveAuthorization = params=>request(`/authorization/saveAuthorization`,params);
    export const postgetAgreement = params=>request(`/authorization/getAgreement`,params);
    export const postIsHongyiqian = params=>request(`/authorization/isHongyiqian`,params);
    export const postHongyiqianRecharge = params=>request(`/authorization/hongYiQianTopUp`,params);
    export const postHongYiQianAddress = params=>request(`/authorization/hongYiQianAddress`,params);


    //刷新页面
    export const postQueryUser = params=>request(`/authorization/queryUser`,params);

    //收货地址  复制并添加 通过id查找收货地址信息
    export const getCopyAdd=params=>request(`/userCenterConsignee/queryConsigneeInfoById`,params);
    
    //获取联动地区数据
    export const getAreaAllData=parmas=>request(`/ds/AREA/1.0.2`,parmas);

    //忘记密码 -- 查询公司是否存在
    export const  inquireCommpany=parmas=>request(`/passwordModificationApply/checkCompanyName`,parmas);

    //忘记密码 -- 找回密码/用户名提交
    export const forgetFindsubimt=parmas=>request(`/passwordModificationApply/saveApply`,parmas);
    
    //下载承诺函模版
    export const getcommitLetterUrl=parmas=>request(`/common/getFPFilePath`,parmas);
    // 找回密码用户名 接受方式 短信验证
    export const getPhoneVfiry=parmas=>request(`/common/sendPhoneCode`,parmas);
    // 子账号待审核列表
    export const getSubAccAduit=parmas=>request(`/subAccount/subAccountApplyPage`,parmas);
    // 子账号管理路径
    export const getSubAccPath=parmas=>request(`/subAccount/subAccountPage`,parmas);
    // 子账号详情
    export const getSubAccDetail=parmas=>request(`/subAccount/subAccountDetails`,parmas);
     // 子账号审核通过
     export const subAccountPass=parmas=>request(`/subAccount/subAccountPass`,parmas);
        // 子账号审核驳回
    export const subAccountNoPass=parmas=>request(`/subAccount/subAccountNoPass`,parmas);
    // 子账号注销
    export const subAccountLogoff=parmas=>request(`/subAccount/subAccountLogoff`,parmas);
    // 获取菜单接口
    export const getAccountPermission=parmas=>request(`/permission/getAccountPermission`,parmas);
    // 拼图验证
    export const imageRandom=parmas=>request(`/verification/imageRandom`,parmas);
    // 品牌列表
    export const pageBrand=parmas=>request(`/brand/pageBrand`,parmas);
    // 品牌add
    export const saveBatch=parmas=>request(`/brand/saveBatch`,parmas);
    // 品牌是否重复
    export const getOne=parmas=>request(`/brand/getOne`,parmas);




//企业担保函
//校验企业用户信息
export const checkAuthentication=parmas=>request(`/electronicGuaranteeLetter/checkAuthentication`,parmas);
// 校验是否有申请的授信
export const checkOldFormerlyCredit=parmas=>request(`/electronicGuaranteeLetter/checkOldFormerlyCredit`,parmas);
// 我的信用查询
export const getCustomGuarantees=parmas=>request(`/electronicGuaranteeLetter/getCustomGuarantees`,parmas);
// 我的信用查询
export const isAgentAgreement=parmas=>request(`/electronicGuaranteeLetter/isAgentAgreement`,parmas);

//更新账户名
export const updateLoginNames=parmas=>request(`/userCenterAccount/updateLoginName`,parmas);

export const remind=parmas=>request(`/commonly/remind`,parmas);//首页站内信提醒
export const read=parmas=>request(`/commonly/read`,parmas);//已读接口