
let baseURL = ""
let uploadURL = ""
let loginURL = ""
let redirectURL = ""
let CSPURL = ''
let memberURL = ''
let serviceURL=''
let istoreUrl=''
let metamallUrl=''
let usercenter=''
let agentClubUrl=''
let wsUrl = ''
if (process.env.REACT_APP_NAME  === 'production') {
    //打包生产环境
    baseURL = "https://i.jiahuaduola.com/gateway/user-web"
    uploadURL = 'https://i.jiahuaduola.com'
    loginURL = 'https://i.jiahuaduola.com/authApi'
    redirectURL = 'https://i.jiahuaduola.com/gateway'
    serviceURL='https://i.jiahuaduola.com/resource'
    memberURL = "https://i.jiahuaduola.com/membercenter/home"  //会员首页地址  
    CSPURL='https://csp.jiahuaduola.com/duola.html' //CSP地址  
    istoreUrl='http://istore.changhongit.com'      //istore 地址      
    usercenter="https://i.jiahuaduola.com"   //用户中心地址  
    agentClubUrl='https://agentclub.jiahuaduola.com'
    metamallUrl="https://metamall.jiahuaduola.com"    //metamall商城地址   四期上线用不到
    wsUrl = `wss://i.jiahuaduola.com/msgApi/ws/userActivateSv`

} else if (process.env.REACT_APP_NAME  === 'development') { //开发环境
    baseURL = '/api'
    serviceURL="/serviceApi"
    // uploadURL = 'http://10.37.125.64:18081'
    uploadURL='https://usercenter-test.jiahuaduola.com'
    loginURL = 'https://usercenter-test.jiahuaduola.com/authApi'
    redirectURL = 'http://usercenter.jiahuaduola.com/gateway'
    CSPURL="http://echannel.icsb.shop/authCallback.html"
    memberURL = "https://usercenter-test.jiahuaduola.com/membercenter/home"
    istoreUrl='http://172.28.11.136'  //istore 地址
    agentClubUrl='https://agentclub-test.jiahuaduola.com'
    metamallUrl="http://metamall-test.jiahuaduola.com/homepage"//metamall商城地址
    usercenter="https://usercenter-test.jiahuaduola.com"   //用户中心地址
    wsUrl = `wss://usercenter-test.jiahuaduola.com/msgApi/ws/userActivateSv`

}else if(process.env.REACT_APP_NAME === 'test'){  //测试环境
    //打包测试环境
    baseURL = "https://usercenter-test.jiahuaduola.com/gateway/user-web"
    uploadURL = 'https://usercenter-test.jiahuaduola.com'
    loginURL = 'https://usercenter-test.jiahuaduola.com/authApi'
    redirectURL = 'https://usercenter-test.jiahuaduola.com/gateway'
    memberURL = "https://usercenter-test.jiahuaduola.com/membercenter/home"
    serviceURL='https://usercenter-test.jiahuaduola.com/resource'
    CSPURL="http://echannel.icsb.shop/authCallback.html"  //CSP 地址
    istoreUrl='http://172.28.11.136'      //istore 地址
    agentClubUrl='https://agentclub-test.jiahuaduola.com'
    metamallUrl="http://metamall-test.jiahuaduola.com/homepage"    //metamall商城地址
    usercenter="https://usercenter-test.jiahuaduola.com" //用户中心
    wsUrl = `wss://usercenter-test.jiahuaduola.com/msgApi/ws/userActivateSv`

}else if(process.env.REACT_APP_NAME === 'uat'){  //预发布环境
    //打包预发布环境
    baseURL = "https://uctest.jiahuaduola.com/gateway/user-web"
    uploadURL = 'https://uctest.jiahuaduola.com'
    loginURL = 'https://uctest.jiahuaduola.com/authApi'
    redirectURL = 'https://uctest.jiahuaduola.com/gateway'
    memberURL = "https://uctest.jiahuaduola.com/membercenter/home"
    serviceURL='https://uctest.jiahuaduola.com/resource'
    CSPURL="http://demobill.icsb.shop/duola.html"                   //CSP 地址
    istoreUrl='http://172.28.11.136'                                //istore 地址
    metamallUrl="http://metamall-test.jiahuaduola.com/homepage"    //metamall商城地址
    usercenter="https://uctest.jiahuaduola.com" //用户中心
    agentClubUrl='https://agentclub-test.jiahuaduola.com'
}

export {baseURL,uploadURL,loginURL,redirectURL,CSPURL,memberURL,agentClubUrl,serviceURL,istoreUrl,metamallUrl,usercenter,wsUrl};
