/*
redux 核心文件 暴露store
*/
import {createStore,applyMiddleware,combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';

//引入各个reducer
import loginInfo from "./reducers/loginInfo";
const allReducers = combineReducers({
   loginInfo
})

export default createStore(allReducers,composeWithDevTools(applyMiddleware(thunk)))