import axios from 'axios';
import { message } from 'antd';
import cache from '../assets/js/cache';
import Toast from '@a/js/toast';
import cookie from 'react-cookies';
import qs from 'querystring';
import { baseURL, loginURL, serviceURL,redirectURL } from './baseUrl';

import history from '@a/js/history.js';
axios.defaults.withCredentials = true;
axios.defaults.timeout = 50000;
axios.defaults.baseURL = baseURL;


let urlContians = [
    '/userCenterConsignee/queryConsigneeInfo',
    '/userCenterConsignee/queryConsigneeInfo',
    '/invoice/selectAuthenticationInfo',
    '/ds/AREA/1.0.2',
    '/userCenterUser/queryHomePage',
    '/userCenterAccount/queryAccount',
    '/authentication/queryAuthInfo',
    '/authorization/queryAuthorizationByAccountId',
    '/userCenterAgreement/queryAgreementRecord',
    '/custom/queryCustomHomePage',
    '/custom/queryCustomInfo',
    '/custom/getPersonIdentityUrl',
    '/invoice/selectCostomInvoice',
    '/authentication/getLegalAgreementUrl',
    '/logout',
    `${loginURL}/oauth/getUserByToken`,
    '/oauth/getUserByToken',
    '/authorization/getAgreement',
    '/verification/imageRandom'
  ];
let authApiArr = [
    '/logout',
    '/client/getUsercenterClient',
    '/oauth/login',
    '/oauth/getUserByToken',
    '/oauth/saveAgreement',
    '/oauth/getSmsCode'
]
axios.interceptors.request.use(
  config => {
    // 是否需要开启防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === true;
    if(config.url !== '/oauth/login'){
      config.headers['Authorization'] = "Bearer "+ localStorage.getItem('token')
    }
    if (config.url === '/logout') {
      cookie.remove('agentClubToken', { path: '/', domain: '.jiahuaduola.com' });
    }
    if (!isRepeatSubmit && config.method === 'post' && urlContians.indexOf(config.url) === -1) {
      const requestObj = {
        url: config.url, //请求接口地址
        data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data, //接口请求参数
        time: new Date().getTime(), //接口发起的当前时间
      };
      //获取上一次接口的相关信息
      const sessionObj = cache.session.getJSON('sessionObj');

      if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
        //存储当前接口的相关信息
        cache.session.setJSON('sessionObj', requestObj);
      } else {
        const s_url = sessionObj.url; // 请求地址
        const s_data = sessionObj.data; // 请求数据
        const s_time = sessionObj.time; // 请求时间
        const interval = 1000; // 间隔时间(ms)，小于此时间视为重复提交
        if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
          const messages = '数据正在处理，请勿重复提交';
          console.warn(`[${s_url}]: ` + messages);
          // message.warn(messages);
          return Promise.reject(new Error(message));
        } else {
          cache.session.setJSON('sessionObj', requestObj);
        }
      }
    }

    if (authApiArr.indexOf(config.url) !== -1) {
      if (process.env.NODE_ENV === 'production') {
        config.baseURL = loginURL;
      } else {
        config.baseURL = '/ccc';
      }
    } else if (config.url === '/baseService/listAll' || config.url === '/ds/AREA/1.0.2') {
      if (process.env.NODE_ENV === 'production') {
        config.baseURL = serviceURL;
      } else {
        config.baseURL = '/serviceApi';
      }
    }

    if (
      config.url === '/userCenterAccount/updatePhone' ||
      config.url === '/userCenterAccount/retrievepwd' ||
      config.url === '/register/register' ||
      config.url === '/oauth/login' ||
      config.url === '/userCenterAccount/retrievepwd' ||
      config.url === '/userCenterAccount/updateEmail' ||
      config.url === '/common/checkSmsCode' ||
      config.url === '/common/checkEmailCode' || 
      config.url === '/loginM/updatePhoneNum' || 
      config.url === '/passwordModificationApply/saveApply' 
    ) {
      config.headers['code-flag'] = localStorage.getItem('code-flag');
    }
    if(config.url === '/common/getSmsCode'){
      config.headers['captcha-flag'] = localStorage.getItem('captcha-flag');
    }
    if(config.url === '/authentication/authenticationSubmit'){
      config.headers['x-request-id'] = localStorage.getItem('x-request-id');
    }
    
    return config;
  },
  error => {
    return Promise.reject(error.message);
  }
);
/**
 * 响应 -- 拦截器
 */
axios.interceptors.response.use(
  response => {
    let path = ['/common/getPhoneCode', '/common/getSmsCode', '/common/sendEmailCode', '/commonly/sendEmailCode', '/common/sendPhoneCode',];
    if (path.indexOf(response.config.url) !== -1) {
      localStorage.setItem('code-flag', response.headers['code-flag'] || response.headers['CODE-FLAG']);
    }
    let _ = ['/common/genCaptcha']
    if(_.indexOf(response.config.url) !== -1){
      localStorage.setItem('captcha-flag', response.headers['captcha-flag'] || response.headers['CAPTCHA-FLAG']);
    }
    return response;
  },
  error => {
    // console.log("请求出错：", error.message);
  }
);

export default function request(url, data = {}, contentType = 'application/json;charset=utf-8', type = 'post', header = '', clientKey = '') {
  let dataP = contentType === 'application/x-www-form-urlencoded;charset=UTF-8' ? qs.stringify(data) : data;
  let curHeader = !header ? { 'Content-Type': contentType } : { 'Content-Type': contentType, 'Authorization': 'Basic ' + clientKey };
  return new Promise((resolve, reject) => {
    axios({
      method: type,
      url: url,
      data: dataP,
      headers: curHeader,
    }).then(res => {
        let data = res.data;
        //特殊接口的处理
        let path = [
          '/login',
          '/client/getUsercenterClient',
          '/oauth/login',
          '/oauth/getUserByToken',
          '/oauth/saveAgreement',
          '/oauth/getSmsCode',
          '/loginM/updateLoginName',
          '/loginM/checkCompanyName',
          '/loginM/updatePhoneNum',
          '/loginM/updateEmail',
          '/common/getPhoneCode',
          '/common/getSmsCode',
          '/common/sendEmailCode',
          '/commonly/sendEmailCode',
          '/common/sendPhoneCode',
          '/userCenterUser/queryUserStatus',
          '/register/register',
          '/userCenterAccount/retrievepwd',
          '/authorization/queryUser',
          '/authorization/isHongyiqian',
          '/passwordModificationApply/checkRefused',
          '/invoice/saveInvoiceInfo',
          '/userCenterLink/saveLinkInfo',
          '/userCenterLink/updateLinkInfo',
          '/erp/applySave',
          '/authentication/authenticationSubmit',
          '/userCenterAccount/updatePhone',
          '/subAccount/subAccountApplyPage',
          `/subAccount/subAccountPage`,
          '/subAccount/subAccountPass',
          '/subAccount/subAccountNoPass',
          '/subAccount/subAccountLogoff',
          '/brand/pageBrand',
          '/brand/saveBatch',
          '/permission/getAccountPermission',
          '/electronicGuaranteeLetter/getCustomGuarantees',
          '/electronicGuaranteeLetter/checkAuthentication',
          '/electronicGuaranteeLetter/checkOldFormerlyCredit',
          '/electronicGuaranteeLetter/isAgentAgreement',
          '/userCenterAccount/updateLoginName',
        ];
        if (data.code === 'A0230' || data.code ==='A0200' || (url === '/logout' && data.code === '0')) {
          localStorage.removeItem('user');
          localStorage.removeItem('ticket');
          localStorage.removeItem('token');
          //在这里跳到登录
          history.push('/login');

          return false;
        }
        if (path.indexOf(url) !== -1) {
          return resolve(data);
        } else if (data.code === '0' || data.code === '2' || data.code === '3') {
          if (data.code === '3' && (url === '/common/checkSmsCode' || url === '/common/checkEmailCode')) {
            Toast.error(data.msg)
          } else if (data.code === '2' || data.code === '3') {
            Toast.error(data.msg)
            return resolve(data.data);
          } else if (data.code === '0') {
            return resolve(data.data);
          }
        } else if (data.code === 'EMAIL_00002') {
          Toast.error(data.msg)
        } else if (data.code === '1') {
          Toast.warning(data?.data);
        } else if (url !== '/logout') {
          Toast.error(data.msg);
        }else{
          Toast.error(data.msg)
        }
      }).catch(error => {
        // console.log(error);
        reject(error)
        // message.error('请求出错了: ' + error.message)
      });
  });
}
