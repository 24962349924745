import Loadable from "react-loadable";
// loading
import Loading from "@c/common/loading/index";

//个人中心
const Login = Loadable({
    loader:()=>import("@p/login"),
    loading:Loading
})

const Forget = Loadable({
    loader:()=>import("@p/Forget"),
    loading:Loading
})

const Register = Loadable({
    loader:()=>import("@p/Register"),
    loading:Loading
})

const Userfront = Loadable({
    loader:()=>import("@p/userfront"),
    loading:Loading
})

const Usercenter = Loadable({
    loader:()=>import("@p/usercenter"),
    loading:Loading
})

const TrustCenter = Loadable({
    loader:()=>import("@p/trustCenter"),
    loading:Loading
})

//项目具体需要什么额外的信息可配置
const routes = [
    {
        path: '/login',
        name:"登录",
        component: Login,
    },
    {
        path: '/forget',
        name:"忘记密码",
        component: Forget,
    },
    {
        path: '/register',
        name:"注册",
        component: Register,
    },
    {
        path: '/userfront',
        name:"前台",
        component: Userfront,
    },
    {
        path: '/usercenter',
        name:"中台",
        component: Usercenter,
    },
    {
        path: '/trustCenter',
        name:"信用中心",
        component: TrustCenter,
    }
]


export default routes;